import { Injectable } from '@angular/core';
import { AESEncryptDecryptServiceService } from './aesencrypt-decrypt-service.service';
import { CookieService } from 'ngx-cookie-service';
import { AUTH_KEYS } from 'src/app/core/enums';
@Injectable({
    providedIn: 'root',
})
export class CommonService {

    constructor(private cookieService: CookieService, private aesService: AESEncryptDecryptServiceService) { }


    get getUserPermissions(): string { return this.cookieService.get(AUTH_KEYS.USER_PERMISSION || '') }

    hasPermissions(permission: string): boolean {
        if (this.aesService.decryptData(this.getUserPermissions).includes(permission)) { return true }
        return false
    }

    hasSubPermissions(permission: string): boolean {
        let returnValue: boolean = false;
        this.aesService.decryptData(this.getUserPermissions).every((ele: string) => {
            // Do your thing, then:
            if (ele.includes(permission)) { returnValue = false; return false }
            else { returnValue = true; return true }
        })
        return returnValue
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
  standalone: true,
  pure: true,
})
export class LimitToPipe implements PipeTransform {
  transform(value: string, limit: number = 30) {
    if (value && value.length > limit) {
      return value.slice(0, limit) + '...';
    } else {
      return value;
    }
  }
}

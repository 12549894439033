import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterEvent,
  RouterModule,
  Event,
  Router,
  NavigationEnd,
} from '@angular/router';
import { MainService } from '../../services';
import { Subscription, filter } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { PaginationConfig } from '../../enums';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  classToggled = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>(
    false,
  );
  private subscription: Subscription = new Subscription();
  public profileDetail: any;
  public notifyList: any;
  public notifyCount: any;

  public filter = {
    sortBy: '',
    orderBy: 'DESC'
  };
  public paginationConfig: PaginationConfig = {
    totalItems: 0,
    itemsPerPage: 20,
    currentPage: 1,
  };

  constructor(private mainService: MainService, private router: Router, private notifiyService: NotificationService) {}

  ngOnInit(): void {
    this.subscription.add(this.mainService.httpgetprofile().subscribe());
    this.subscription.add(
      this.mainService.profileDetail$.subscribe((res) => {
        this.profileDetail = res ? res : '';
      }),
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter((e: Event): e is RouterEvent => e instanceof NavigationEnd),
        )
        .subscribe((e: RouterEvent) => {
          this.classToggled = false;
          this.toggleSidebar.emit(this.classToggled);
        }),
    );

    this.getNotifyList();
  }

  public toggleField() {
    this.classToggled = !this.classToggled;
    this.toggleSidebar.emit(this.classToggled);
  }

  getNotifyList() {
    this.subscription.add(
      this.notifiyService.httpGetNotificationList(this.paginationConfig.itemsPerPage, this.paginationConfig.currentPage,this.filter.orderBy).subscribe({
        next: ({ data }) => {
          this.notifyList = data && data.data ? data.data : [];
          this.notifyCount = data?.count
        },
      }),
    );
  }

  readNotify(id:string, type:string){
    this.subscription.add(
      this.notifiyService.httpUpdateNotifyStatus(id).subscribe({
        next: ({ data }) => {
          if (data && data.code) {
            this.getNotifyList();
            this.router.navigate(['panel/notification/detail',id])
           }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Injectable, inject } from '@angular/core';
import { AUTH_KEYS } from '../enums';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptServiceService } from 'src/app/shared/public-api';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(
    private cookieService: CookieService,
    private aes: AESEncryptDecryptServiceService,
  ) {}

  public set setTokenSession(data: string) {
    if (data) {
      const tokenData = data ? this.aes.encryptData(data) : '';
      this.cookieService.set(AUTH_KEYS.ADMIN_TOKEN, tokenData, { path: '/' });
    }
  }

  public set setUserPermission(data: string) {
    if (data) {
      const tokenData = data ? this.aes.encryptData(data) : '';
      this.cookieService.set(AUTH_KEYS.USER_PERMISSION, tokenData, { path: '/' });
    }
  }

  public get getTokenSession() {
    let tokenSession = this.cookieService.get(AUTH_KEYS.ADMIN_TOKEN);
    if (tokenSession) {
      return this.aes.decryptData(tokenSession);
    } else {
      return;
    }
  }

  public clearTokenSession() {
    this.deleteCookies();
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  // function to delete cookies
  deleteCookies() {
    this.cookieService.delete(AUTH_KEYS.ADMIN_TOKEN, '/');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serialNo',
  pure: true,
  standalone: true,
})
export class SerialNumberPipe implements PipeTransform {
  transform(value: number, ...args: any[]) {
    if (
      value > -1 &&
      args &&
      args[0] &&
      args[0]['currentPage'] &&
      args[0]['itemsPerPage']
    ) {
      return (
        (args[0]['currentPage'] - 1) * args[0]['itemsPerPage'] + (value + 1)
      );
    } else {
      return value ? value + 1 : 1;
    }
  }
}

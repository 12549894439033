import { Injectable } from "@angular/core";
import { Lightbox } from "ngx-lightbox";

@Injectable({
    providedIn: "root"
})
export class LightboxService {

    constructor(
        private _lightbox: Lightbox
    ) {}

    public zoomImage(_album: any, i: number) {
        return this._lightbox.open(_album, i);
      }
}
<header class="main-header primary-bg py-2">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6 col-12">
        <div class="brand-logo position-relative">
          <a routerlink="/"
            ><img
              alt=" "
              class="img-fluid img-loog"
              src="assets/images/aminLogo.png"
          /></a>
          <div class="mobile-menu" (click)="toggleField()">
            <i class="fas fa-bars"></i>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-2 mt-md-0">
        <div
          class="nav-rigt w-max-content ms-auto d-flex align-items-center justify-content-md-end justify-content-between"
        >

        <div class="panel panel-default">
          <div class="panel-body">
            <!-- Single button -->
            <div class="btn-group pull-right top-head-dropdown me-4">
              <button type="button" class="btn btn-default dropdown-toggle no-drop position-relative"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="assets/images/bell.svg" class="width-24" alt="Notification" title="Notification">
                <span *ngIf="notifyCount>0" class="counter position-absolute rounded-pill text-white">{{notifyCount}}</span>
              </button>
              <div class="notification-drop dropdown-menu p-0">
                <div *ngIf="notifyList?.length===0" class="no-nofication text-center my-4 px-3">
                  <img src="assets/images/no-notification.png" class="mx-auto my-3">
                  <h5 class="fs-18">No Notifications Found</h5>
                  <p class="fs-13 text-grey-custom fw-500">You have currently no notificatons. We'll notify
                    you when something new arrives'</p>
                </div>
              <ul class="dropdown-menu-right p-0 mb-0"> 
                <li *ngFor="let item of notifyList" >  
                  <a (click)="readNotify(item.id, item.type)" class="top-text-block d-flex">
                    <!-- <div class="user rounded-pill me-2 overflow-hidden">
                      <img src="assets/images/profile-img.jpeg" alt="">
                      <span class="d-none">AK</span>
                    </div> -->
                    <div class="wrap">
                    <div class="top-text-heading fs-15">{{item?.subject}}</div>
                    <div class="top-text-light">{{item?.created_at | date }}</div>
                  </div>
                  </a> 
                </li>
              </ul>
              <div *ngIf="notifyCount>0" class="clear d-flex justify-content-between">
                <span routerLink="/panel/notification" class="cursor-pointer markread">View all</span>
              </div>
            </div>
            </div>
          </div>
        </div>
        
          <a
            routerLink="/panel/manage-profile"
            class="menu-link menu-item text-decoration-none text-white d-flex align-items-center me-2"
            ><svg
              id="person-square"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path
                _ngcontent-tcp-c3=""
                id="Path_9"
                data-name="Path 9"
                d="M11,6A3,3,0,1,1,8,3a3,3,0,0,1,3,3Z"
                fill="#f5f5f5"
              ></path>
              <path
                _ngcontent-tcp-c3=""
                id="Path_10"
                data-name="Path 10"
                d="M2,0A2,2,0,0,0,0,2V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2ZM14,1a1,1,0,0,1,1,1V14a1,1,0,0,1-1,1V14c0-1-1-4-6-4s-6,3-6,4v1a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1Z"
                fill="#f5f5f5"
              ></path>
            </svg>
            <span class="ms-2">{{
              profileDetail &&
              profileDetail.profile &&
              profileDetail.profile.name
                ? profileDetail.profile.name
                : ''
            }}</span>
          </a>

          <!-- <div class="dropdown w-max-content ps-1"><button 
                            type="button" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle"><span
                                _ngcontent-tcp-c3=""><img _ngcontent-tcp-c3="" src="assets/img/britain.png">
                                EN</span> </button>
                        <ul   class="dropdown-menu">
                            <li><a class="dropdown-item"><img
                                        _ngcontent-tcp-c3="" src="assets/img/britain.png"> English</a></li>
                            <li><a class="dropdown-item"><img
                                        src="assets/img/germany.png"> German</a></li>
                        </ul>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</header>

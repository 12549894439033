export const PermissionKeys = {
    userRead: 'users:read',
    roleRead: 'roles:read',
    adminAllRights: "admin:all-rights",
    rolesWrite: "roles:write",
    usersWrite: "users:write",
    campaignRead: "campaign:read",
    campaignWrite: "campaign:write",
    projectRead: "project:read",
    projectWrite: "project:write",
    articleRead: "article:read",
    articleWrite: "article:write",
    toolboxRead: "toolbox:read",
    toolboxWrite: "toolbox:write",
    categoryRead: "category:read",
    categoryWrite: "category:write",
    reportRead: "report:read",
    reportWrite: "report:write",
    newsRead: "news:read",
    newsWrite: "news:write",
    newsCategoryRead: "news-category:read",
    newsCategoryWrite: "news-category:write",
    eventsRead: "events:read",
    eventsWrite: "events:write",
    privacyPolicyRead: "privacy-policy:read",
    privacyPolicyWrite: "privacy-policy:write",
    termConditionRead: "term-condition:read",
    termConditionWrite: "term-condition:write",
    aboutUsRead: "about-us:read",
    aboutUsWrite: "about-us:write"
}
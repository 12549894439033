import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, TimeoutError, catchError, map, throwError, timeout } from 'rxjs';
import { AESEncryptDecryptServiceService } from 'src/app/shared/services/aesencrypt-decrypt-service.service';
import { environment } from 'src/environment/environment';
import { EventBusService, TokenStorageService } from '../services';
import { EmitEvent } from '../helpers';
import { Events } from '../enums';
import { CustomMessageService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';

const apiUrl = environment.apiBaseUrl;
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private requestCount: number = 0;

  constructor(private aes: AESEncryptDecryptServiceService, private tokenStorageService: TokenStorageService,
    private eventBus: EventBusService, private toastService: CustomMessageService, private router: Router,
    private route: ActivatedRoute, @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    if(!authReq.url.includes('agg/users') && !authReq.url.includes('agg/campaigns')) {
      this.eventBus.emit(new EmitEvent(Events.LOADING_START));
    }
    if (this.tokenStorageService.getTokenSession && !authReq.url.includes('/assets/i18n')) {
      let headers = this.addHeader;
      authReq = req.clone({ url: `${apiUrl}${req.url}`, setHeaders: headers });
    } else if (!authReq.url.includes('/assets/i18n')) {
      let reqParam = { url: `${apiUrl}${req.url}` };
      if ((this.router.url.includes('/auth/set-password') && this.route.snapshot.queryParams['token']) ||
      (this.router.url.includes('/login') && this.route.snapshot.queryParams['token'])) {
        Object.assign(reqParam, { setHeaders: this.addHeader });
      }
      authReq = req.clone(reqParam);
    } else { authReq = req; }

    const formDataCheck = authReq.body instanceof FormData;

    switch (authReq.method) {
      case 'GET':
        this.aes.encryptData(authReq.urlWithParams);
        break;
      case 'PUT':
        authReq = authReq.clone({ body: { data: this.aes.encryptData(authReq.body) } });
        break;
      case 'POST':
        if (!formDataCheck) {
          authReq = authReq.clone({ body: { data: this.aes.encryptData(authReq.body) } });
        }
        break;
      case 'DELETE':
        authReq = authReq.clone({ body: { data: this.aes.encryptData(authReq.body) } });
        break;
      case 'PATCH':
        if (!formDataCheck) {
          authReq = authReq.clone({ body: { data: this.aes.encryptData(authReq.body) } });
        }
        break;
    }

    this.requestCount++;
    return next.handle(authReq).pipe(
      timeout(this.defaultTimeout || 30000),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.requestCount--;
          if (this.requestCount <= 0) {
            this.eventBus.emit(new EmitEvent(Events.LOADING_END));
          }
          if (event && event.body && event.body.data) {
            const data = JSON.parse(this.aes.decryptData(event.body.data));
            event = event.clone({ body: { data } });
          }
          return event;
        } else { return event; }
      }),
      catchError((error) => {
        let errorMessage: any = '';
        this.requestCount--;
        if (error.status === 401) {
          this.toastService.showHttpMessages('Sesion Expired please re-login', 'error', 'Error')
          this.eventBus.emit(new EmitEvent(Events.SIGNOUT));
        }
        else if (error instanceof TimeoutError) { errorMessage = 'Timeout occured please try again'; }
        else {
          errorMessage = error && error.error && error.error.data ? JSON.parse(this.aes.decryptData(error.error.data)) : 'Something went wrong!';
        }
        return throwError(() => {
          if (this.requestCount <= 0) { this.eventBus.emit(new EmitEvent(Events.LOADING_END)); }
          return [errorMessage, this.toastService.showHttpMessages(errorMessage['code'] ? errorMessage['code'] : '', 'error', 'Error'),
            error.error.data ? JSON.parse(this.aes.decryptData(error.error.data)) : '',
          ];
        });
      }),
    );
  }

  private get addHeader() {
    if ((this.router.url.includes('/auth/set-password') && this.route.snapshot.queryParams['token']) ||
    (!this.tokenStorageService.getTokenSession && this.router.url.includes('/login'))) {
      return { Authorization: 'Bearer ' + this.route.snapshot.queryParams['token'] };
    } else { return { Authorization: 'Bearer ' + this.tokenStorageService.getTokenSession }; }
  }
}

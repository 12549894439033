export enum ModalFlagEvents {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
}

export interface PaginationConfig {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  limits?: Array<number>;
}

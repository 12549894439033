<div *ngIf="control && control?.errors && (control?.dirty || control?.touched)" class="danger" id="error-message">
  <div *ngIf="control?.errors?.required">
    {{ controlName }}
    {{ 'is required' }}
  </div>
  <div *ngIf="control.errors.maxlength">
    {{ controlName }}
    {{ 'should only contain ' + control.errors.maxlength['requiredLength'] + ' characters' }}
  </div>
  <div *ngIf="control.errors.minlength">
    {{ controlName }}
    {{ 'should contain minimum ' + control.errors.minlength['requiredLength'] + ' characters'}}
  </div>
  <div *ngIf="control?.errors?.pattern">
    {{ controlName }}
    {{ ' is invalid' }}
  </div>
  <div *ngIf="control?.errors?.hasCapitalCase || control?.errors?.hasSmallCase || control?.errors?.hasSpecialCharacters 
    || control?.errors?.hasNumber">
    {{ controlName }}
    {{ control?.errors?.hasCapitalCase ? ' has atleast one upper case letter'
    : control?.errors?.hasSmallCase ? ' has atleast one small case letter'
    : control?.errors?.hasSpecialCharacters ? ' has atleast one special character'
    : control?.errors?.hasNumber ? ' has atleast one numeric character' : ' is invalid' }}
  </div>
  <div *ngIf="control.errors.editorMaxChar">{{ controlName }} should only contain 5000 characters</div>
  <div *ngIf="control.errors.editorMinChar">{{ controlName }} should contain 25 characters.</div>
</div>
export type ErrorTypes =
  | 'required'
  | 'email'
  | 'minlength'
  | 'pattern'
  | 'maxlength'
  | 'hasCapitalCase'
  | 'hasSmallCase'
  | 'hasSpecialCharacters'
  | 'hasNumber'
  | 'noWhiteSpaceValidator';

export const ERROR_MESSAGES: { [key: string]: (...args: any) => string } = {
  required: (formControlName: string) => `${formControlName} is required.`,

  email: () => `Please enter a valid email address.`,
  minlength: (formControlName, requirement) =>
    `${formControlName} should be at least ${requirement} characters.`,
  maxlength: (formControlName, requirement) =>
    `${formControlName} should not be more than ${requirement} characters.`,
  pattern: (formControlName) => `Please enter valid ${formControlName}`,
  hasCapitalCase: () => `Must contain atleast one uppercase letter`,
  hasSmallCase: () => `Must contain atleast one smallcase letter`,
  hasSpecialCharacters: () => `Must contain atleast one special letter`,
  hasNumber: () => `Must contain atleast one numeric character`,
  noWhiteSpaceValidator: () => 'Must not contain empty space',
};

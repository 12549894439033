import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TitleStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleService, PageLoaderComponent } from './shared/public-api';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from './i18n/i18n.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  declarations: [AppComponent, PageLoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    I18nModule,
    NgbModule,
    PaginationModule,
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut:5000,
    }),
    ImageCropperModule,
    SlickCarouselModule,
    LightboxModule
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleService,
    },
    NgbActiveModal,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export const environment = {
  production: false,
  // apiBaseUrl: 'https://api-yap.signitydemo.in/api/',
   apiBaseUrl: 'https://yapgateway.signitydemo.in/api/',


  //  old url
  // imageUrl: 'https://d1e6xkshl5kns1.cloudfront.net/',
  imageUrl: 'https://d3fi8ele23t57x.cloudfront.net/',
  
  // reportUrl: 'https://yap.signitydemo.in/'
  reportUrl: 'https://yapweb.signitydemo.in/'
  
};

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { EventBusService } from 'src/app/core/services';
import { EmitEvent } from 'src/app/core/helpers';
import { Events } from 'src/app/core/enums';
import { CommonService } from '../../services/common.service';
import { PermissionKeys } from '../../constants';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public subMenu: boolean = false;

  public permission = PermissionKeys;

  constructor(private eventBusService: EventBusService, public commonService: CommonService) { }

  logout() {
    this.eventBusService.emit(new EmitEvent(Events.SIGNOUT));
  }

  showSubMenu(){
    this.subMenu = !this.subMenu
  }
}

export const API_Routes = {
  AUTH: {
    LOGIN: 'auth/login?type=password',
    FORGOT_PWD: 'auth/forgot-password',
    RESET_PWD: 'auth/reset-password',
    OTP_LOGIN: 'auth/login?type=otp',
    ACCOUNT_STATUS: 'auth/accounts',
    ACTIVATE: 'auth/accounts/active-user',
  },
  COMMON: {
    INTEREST: 'setting/enum/interests',
    COUNTRY: 'setting/enum/countries',
    USER: 'agg/users',
    NOTIFY_SETTING: 'notifications/get_notification_settings/all',
    NOTIFY_STATUS: 'notifications/change_notification_setting',
    RESTRICTED_CHAT: 'setting/chatSetting',
    LANGUAGES: 'setting/languages'
  },
  ROLES: {
    ADD: 'auth/roles',
    LIST: 'auth/roles',
    GET_LAST_POSITION: 'auth/roles/last-sequence',
  },
  PERMISSIONS: {
    GET: 'auth/permissions',
    UPDATE: 'auth/roles/permissions',
  },
  PROFILE: {
    GET: 'agg/users/self',
  },
  USERS: {
    ADD: 'agg/users',
    LIST: 'agg/users',
    USER_LIST: 'agg/users/user-listing',
    GET_COUNTRY: 'users/countries',
    COUNTRY: 'setting/enum/countries',
    UPDATE: 'agg/users',
    CSV_UPLOAD: 'agg/users/import-CSV',
    DOWNLOAD_LIST: 'agg/users/listing/download',
    POINT: 'users/profile-points/user-total-points'
  },
  CATEGORY: {
    ADD: 'toolbox/categories',
    POST: 'agg/toolbox',
    UPDATE: 'toolbox/categories',
    LIST: 'toolbox/categories',
  },
  BANNER: {
    ADD: 'setting/enum/banner',
    POST: 'setting/enum/banner',
    UPDATE: 'setting/enum/banner',
    LIST: 'setting/enum/banner',
    DELETE: 'setting/enum/banner',
  },
  FOOTER: {
    GET: 'setting/footer',
    ADD: 'setting/footer/addSocialLinks',
    GETLINK: 'setting/footer/socialLink',
  },
  TOOLBOX: {
    ADD: 'setting/enum/categories',
    UPDATE: 'setting/enum/categories',
    LIST: 'toolbox/categories/',
    POSTLIST: 'agg/toolbox',
    POSTLISTBYID: 'toolbox/posts',
    GET_CATEGORY: 'toolbox/posts/get-category',
    ADD_CATEGORY_POST: 'toolbox/posts'
  },
  ARTICLES: {
    ADD: 'articles',
    LIST: 'agg/articles',
    USER: 'agg/articles',
    GETBYID: 'agg/articles/getSelf'
  },
  TERMS_PAGES: 'setting/pages',

  CAMPAIGN: {
    LIST: 'agg/campaigns',
    ADD: 'campaigns',
    GETBYID: 'agg/campaigns/getSelf',
    USER: 'agg/users',
    PROJECTSLISTING: 'projects/projects-under-campaign'
  },
  PROJECT: {
    LIST: 'agg/projects',
    ADD: 'projects',
    GETBYID: 'agg/projects/getSelf',
    USER: 'agg/users',
    CONTRIBUTIONBYPROJECT: 'agg/projects/contributions'
  },
  DASHBOARD: {
    LIST: 'agg/dashboard',
    DASHBOARD_BADGES: 'users/profile-badges/dashboard-count',
    DASHBOARD_USER_GRAPH: 'auth/monthly-registered-users'
  },
  News: {
    LIST: 'agg/news',
    ADD: 'news'
  },
  REPORT: {
    LIST: 'agg/spam_reports',
    DELETE: 'auth/accounts'
  },
  HEADER: {
    BELL: 'notifications/bell_icon/notification',
    NOTIFY: 'notifications',
    READ: 'notifications/is_read'
  },
  EVENT: {
    ADD: 'event',
    LIST: 'agg/event'
  },
  COMMUNITY: {
    LIST: 'agg/community',
    ADD: 'community'
  },
  SUPPORT: {
    LIST: 'setting/contactForm',
  },
  NEWSCATEGORY: {
    LIST: 'news/category/get-all',
    ADD: 'news/category/',
    GETBYID: 'news/category'
  },
  LEADER_BOARD: {
    POINT_LIST: 'setting/activity/points',
    BADGE_LIST: 'setting/activity/badges',
    TOP_USERS: 'agg/users/profile-points/points-listing',
    DOWNLOAD_LIST: 'agg/users/download/topUserListing'
  },
  ANNOUNCEMENT: {
    POST: 'setting/announcement',
    NOTIFY: 'agg/settings'
  },
};

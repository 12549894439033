import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnsureImportedOnceModule } from './ensureImportOnce.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_TIMEOUT, HttpInterceptorService } from './interceptors';
import { EventBusService } from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 30000,
    },
    EventBusService,
  ],
})
export class CoreModule extends EnsureImportedOnceModule {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
